import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";
import { useCookies } from "react-cookie";
import { useApiStore } from "@/store/hooks";

import { User } from "@/Models/User";

export const CONSTANTS = {
  AUTH_RESTORE: "AUTH_RESTORE",
  AUTH_RESTORE_FAILED: "AUTH_RESTORE_FAILED",
  AUTH_SIGN_IN: "AUTH_SIGN_IN",
  SET_LAST_ACTION_DATE: "SET_LAST_ACTION_DATE",
  SIGN_OUT: "SIGN_OUT",
};

const INITIAL_STATE = {
  lastActionDate: null,
  restoring: true,
  user: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.AUTH_RESTORE:
      return {
        ...state,
        restoring: false,
        user: action.user,
      };
    case CONSTANTS.AUTH_RESTORE_FAILED:
      return {
        ...state,
        restoring: false,
      };
    case CONSTANTS.AUTH_SIGN_IN:
      return {
        ...state,
        user: action.user,
      };
    case CONSTANTS.SET_LAST_ACTION_DATE:
      return {
        ...state,
        lastActionDate: action.lastActionDate,
      };
    case CONSTANTS.SIGN_OUT:
      return {
        ...state,
        user: null,
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useAuthStore = () => {
  const [cookies, _setCookies, _removeCookies] = useCookies(["token", "user"]);

  const api = useApiStore();
  const { dispatch, state } = useContext(Context);

  const checkAlreadySignIn = useCallback(() => {
    return !!cookies.user; // boolean =?>
  }, [cookies]);

  const restore = useCallback(() => {
    return api
      .post("/auth/restore")
      .then((res) => {
        const _user = localStorage.getItem("user");

        let user = {};

        if (_user) {
          user = {
            ...JSON.parse(_user),
            ...new User(res.user),
          };
        } else {
          user = new User(res.user);
        }

        dispatch({ type: CONSTANTS.AUTH_RESTORE, user });
        return res.user;
      })
      .catch(() => {
        dispatch({ type: CONSTANTS.AUTH_RESTORE_FAILED });
        // alert(e.message);
      });
  }, [api.post, dispatch]);

  const setLastActionDate = useCallback(
    (date) => {
      dispatch({ type: CONSTANTS.SET_LAST_ACTION_DATE, lastActionDate: date });
    },
    [dispatch],
  );

  const signIn = useCallback(
    (data) => {
      return api.post("/auth/signin", data);
    },
    [api.post],
  );

  const signInWithCode = useCallback(
    (data) => {
      return api.post("/auth/signin/code", data).then((res) => {
        let user = new User(res.user);

        dispatch({ type: CONSTANTS.AUTH_SIGN_IN, user: user });

        localStorage.setItem(
          "user",
          JSON.stringify({
            authority: user.authority,
            rider: user.rider,
            type: user.type,
            isKurly: user.isKurly,
            username: user.username,
          }),
        );

        return user;
      });
    },
    [api.post, dispatch],
  );

  const signInExistingUser = useCallback(() => {
    dispatch({ type: CONSTANTS.AUTH_SIGN_IN, user: new User(cookies.user) });
  }, [dispatch, cookies]);

  const signOut = useCallback(async () => {
    await api.post("/auth/signout").then(() => {
      dispatch({ type: CONSTANTS.SIGN_OUT });
    });
  }, [api.post, dispatch]);

  const updatePassword = useCallback(
    (body) => {
      return api.put(`/auth/password`, body);
    },
    [api.put],
  );

  return {
    state,
    checkAlreadySignIn,
    restore,
    setLastActionDate,
    signIn,
    signInWithCode,
    signInExistingUser,
    signOut,
    updatePassword,
  };
};
